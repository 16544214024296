<template>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
   <link rel="icon" href="img/favicon.ico">
  <!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" type="text/css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/font-awesome.min.css" type="text/css">-->
  <link rel="stylesheet" href="theme.css" type="text/css"> 
  <title>Porto - Portal de Autonegociação</title>
</head>

<body>
  <div class="capa2">
    <div class="container">
      <div class="row" style="">
        <div class="mx-auto p-4 col-lg-4 col-10" style=""></div>
        <div class="col-md-4" style="">
          <div class="mx-auto bg-light p-3 lpp4">
            <div class="border-top-0 px-3 pt-5">
              <h5 class="mb-5 text-center" contenteditable="true">Informe o código recebido:<br></h5>
              <form v-on:submit="login" v-on:submit.prevent >
                <div class="form-group mb-3 ">
                  <div class="form-group mb-3 ">
                    <input type="password" class="form-control" placeholder="Digite o código de acesso" id="form" label="Digite"
                    v-model="form.promoCode"
                    :error-messages="promoCodeErrors"
                    :disabled="loading"
                    @input="$v.form.promoCode.$touch()"
                    @blur="$v.form.promoCode.$touch()">
                  </div>
                  <!-- <input type="checkbox" name="termo-de-uso" id="termo-de-uso" class="float-left mt-1" v-model="form.termsAcepted">
                  <p class="small ml-4"> Aceito os <a class="text-primary" data-target="#termos-de-uso" data-toggle="modal" href="#">Termos de negociação.</a>
                  </p> -->
                </div>
                <button v-if="!loading" type="submit" class="btn btn-primary btn-lg d-flex my-4 mx-auto">Entrar</button>
              </form>
              <div class="mt-3">
                <ProgressLoading v-bind:loading="loading"/>
              </div>
              <p class="small text-center mt-4"> 
                  <a @click="voltar" class="text-primary float-left" href="#" style="text-decoration: none;">Voltar</a>
                </p>
              <p class="small text-center mt-4"> 
                <a @click="sendCodeForm" class="text-primary float-right" href="#" style="text-decoration: none;">Não recebi o código</a>
              </p>
            </div>
          </div>
        </div>
        <div class="mx-auto p-4 col-lg-4 col-10" style=""></div>
      </div>
    </div>
  </div>
</body>

</html>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ProgressLoading from './ProgressLoading.vue';
import { initializeGlobals } from '@/global';

export default {
  name:'possuo-o-codigo',
    components: {
        ProgressLoading
    },
    mixins: [validationMixin],
    directives: {mask},
    validations: {
        form: {
            promoCode: {required, minLength: minLength(5)},
        }
    },
    data: () => ({
        form: {
            promoCode: null,
            // termsAcepted: false,
        },
        error: {
            alert: false,
            status: null,
            message: null
        },
        loading: false,
        dialog: false,
        largura: null,
        userDataStorage: {},
        pageLocation: null,
        protocol: null,
        host: null,
        ipUser: null
    }),

    async created() {
        this.updateCustomData();
        this.ipUser = localStorage.getItem('ipUser');
        localStorage.setItem("currentPageName", this.$options.name);
        this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
        this.largura = window.screen.width;
        this.protocol = window.location.protocol;
        this.host = window.location.host;
        this.pageLocation = this.userDataStorage.page_location;
        this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.pageLocation, 'possuo-o-codigo');
        this.$emit('updatePageView', this.pageLocation);
    },
    computed: {
      
        ...mapState('auth', ['contacts']),

        classMessage: function () {
            return {
                'text-danger': this.error.status == "ERROR",  
                'text-muted': !this.error.status == "ERROR"
            }
        },

        promoCodeErrors () {
            const errors = [];

            if(!this.$v.form.promoCode.$dirty) return errors;
            !this.$v.form.promoCode.required && errors.push('Código é obrigatório!');
            !this.$v.form.promoCode.minLength && errors.push('O Código não está completo!');

            return errors;
        },
    },
    methods: {
        ...mapActions('auth', ['ActionDoLogin', 'ActionSetCurrentForm']),

        async login() {
            this.eventClickToEnterGTM();
            this.promoCodeErrors.pop();
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.loading = false;
                  this.flashMessage.show({
                    timeout: 300,
                    status: "error",
                    position: "top right",
                    title: "Erro",
                    message: "Informe o código de acesso",
                  });
                  return;
            } else {
                this.loading = true;
                let debtorId = this.contacts.DebtorID;
                let promoCode = this.form.promoCode;

                try {
                    await this.ActionDoLogin({
                        DebtorID: debtorId,
                        PromoCode: promoCode,
                        device_name: process.env.VUE_APP_API_DEVICE,
                        IP: this.ipUser ? this.ipUser : '',
                        OS:navigator.platform
                    })
                    this.loading =  false;
                    
                    this.eventCallbackConfirmSuccessGTM();
                    this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol + '//' + this.host + '/dashboard', 'Dashboard');
                    
                   this.$router.push({ name: 'dashboard' });
                } catch (error) {
                    this.flashMessage.show({
                        timeout:300,
                        status: 'error',
                        position:'top right', 
                        title: 'Erro', 
                        message: 'CPF/CNPJ ou código de acesso incorretos'});
                    this.loading = false;
                    this.eventCallbackToEnterFailureGTM(error);
                    return;
                }
            }
        },
        sendCodeForm() {
            this.eventClickNoReceiveCodeGTM();
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol + '//' + this.host + '/receber-codigo', 'receber-codigo')
            this.ActionSetCurrentForm('form-send-code');
        },
        voltar() {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol + '//' + this.host, 'Login')
            this.updateCustomData();
            this.ActionSetCurrentForm("form-doc-number");
        },

        buildAndSetUserData(doc, id, pageLocation, pageName) {
          const userData = {
                          cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                          id: id, // Substitua pelo ID real
                          page_location: pageLocation,
                          pageName: pageName
                        };
                        localStorage.setItem("userData", JSON.stringify(userData));
        },

        eventClickNoReceiveCodeGTM() {

          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = 'click:link:nao-recebi-o-codigo';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log('eventData click Não recebi o codigo: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventClickToEnterGTM() {

          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = 'click:button:entrar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log('eventData click Entrar: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventCallbackConfirmSuccessGTM() {
          const typesent = localStorage.getItem("typesent");
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction =`token:${typesent}:sucesso`;

          const eventData = {
            event: 'token',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'validado',
          };

          console.log("eventData SUCESSO: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'token', ...eventData });
        },

        eventCallbackToEnterFailureGTM(error) {
                    const pageName = this.$options.name;
                    const eventCategory = `portal-de-negociacao:porto:${pageName}`;
                    const eventAction = 'informar-codigo-de-acesso:alert';
                    const eventLabel = error.data.message;
                    const eventCode = 401;
                    const eventErrorService = '';
                    const eventAlertService = error.data.message;
                    const eventAlert = 'token';
                    const eventAlertType = 'erro';
                    const eventData = {
                      event: 'alert',
                      ev_category: eventCategory,
                      ev_action: eventAction,
                      ev_label: eventLabel,
                      alert_code: eventCode,
                      error_service: eventErrorService,
                      alert_service_message: eventAlertService,
                      alert_event: eventAlert,
                      alert_type: eventAlertType,
                    };

                    console.log("eventData - Código ERRO: " + JSON.stringify(eventData))

                    window.dataLayer.push({ event: 'alert', ...eventData });
        },

        updateCustomData() {
          const globals = initializeGlobals();
          window.customData = globals.customData;
        }
        
    },
}
</script>
<style scoped>


</style>