<template>
  <div class="home-style" style="margin-top: -55px;">
    <!-- <div class="container"> -->
      <div class="row">
        <!-- <div class="col-md-6 px-4">
          <img v-if="imageBackground != null" class="img-fluid d-block" v-bind:src="imageBackground" />
          <h3  v-if="imageBackground == null" class="display-4 text-left my-4 ml-4 px-5">Negocie aqui suas pendências.</h3>
        </div> -->
        <div class="col-12 col-md-12 " style="padding: 0px;">
            <FormLogin class="mx-auto bg-white shadow rounded p-12"></FormLogin>
        </div>
      </div>
      <!-- </div> -->
  </div>

</template>

<script>

import FormLogin from "./components/FormLogin";

export default {
  components: {
    FormLogin,
  },
  data: () => ({
      sheet: false,
      themeBackground :'/img/lp1.jpg',
      imageBackground :null,
  }),
};
</script>
