<template>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" href="img/favicon.ico">
  <link rel="stylesheet" href="theme.css" type="text/css"> 
  <title>Porto - Portal de Autonegociação</title>
</head>

<body>
  <div class="capa1">
    <div class="container">
      <div class="row">
        <div class="p-4 col-lg-4 col-10"></div>
        <div class="col-lg-4">
          <div class="mx-auto bg-light p-3 lpp4">
            <div class="border-top-0 px-3 pt-5">
              <h6 class="text-center mt-3 mb-4">Digite o código de acesso enviado.</h6>
              <form v-on:submit="login" v-on:submit.prevent>
                <div class="form-group mb-3 ">
                  <div class="form-group mb-3">
                    <input type="password" class="form-control form-control-sm col-9 mx-auto" id="form" 
                    v-model="form.promocode"
                    @change="eventChangeAccessCodeInputGTM()">
                  </div>
                </div>
                  <button type="submit" :disabled="loading" id="confirm" class="btn btn-primary btn-lg d-flex my-4 mx-auto">Confirmar</button>

              </form>
              <div class="mt-3">
                <ProgressLoading v-bind:loading="loading"/>
              </div>
              <p class="small text-center mt-4"> <a @click="voltar" class="text-primary" href="#">Reenviar código</a>
              </p>
            </div>
          </div>
        </div>
        <div class="p-4 col-lg-4 col-10"></div>
      </div>
    </div>
  </div>
</body>

</html>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ProgressLoading from './ProgressLoading.vue';
import { initializeGlobals } from '@/global';

export default {
    name: 'codigo-enviado',
    components: {
        ProgressLoading
    },
    mixins: [validationMixin],
    directives: {mask},
    validations: {
        form: {
            promoCode: {required, minLength: minLength(5)},
        }
    },
    data: () => ({
        form: {
            promocode: null

        },
        error: {
            alert: false,
            status: null,
            message: null
        },
        loading: false,
        dialog: false,
        largura: null,
        userDataStorage: {},
        pageLocation: null,
        url: null,
        protocol: null,
        path: null,
        host: null,
        ipUser: null
    }),

    async created() {
      this.updateCustomData();
      this.ipUser = localStorage.getItem('ipUser');
      localStorage.setItem("currentPageName", this.$options.name);
      this.largura = window.screen.width;
      this.protocol = window.location.protocol;
      this.host = window.location.host;
      this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
      this.pageLocation = this.userDataStorage.page_location;
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.pageLocation, 'codigo-enviado');
      this.$emit('updatePageView', this.pageLocation);
    },
    computed: {
        ...mapState('auth', ['contacts']),

        promoCodeErrors () {
            const errors = [];

            if(!this.$v.form.promoCode.$dirty) return errors;
            !this.$v.form.promoCode.required && errors.push('Código é obrigatório!');
            !this.$v.form.promoCode.minLength && errors.push('O Código não está completo!');

            return errors;
        },
    },
    methods: {
        ...mapActions('auth', ['ActionDoLogin', 'ActionSetCurrentForm']),

        async login() {
          this.eventConfirmCodeGTM();
            this.promoCodeErrors.pop();
            this.$v.$touch();

                if(!this.form.promocode || this.form.promocode === "") {
                  this.flashMessage.show({
                        timeout:300,
                        status: 'error',
                        position:'top right', 
                        title: 'Erro', 
                        message: 'Informe o código de acesso'});
                    this.loading = false;
                    return;
                }

                this.loading = true;
                let debtorId = this.contacts.DebtorID;
                let promoCode = this.form.promocode;

                try {
                    await this.ActionDoLogin({
                        DebtorID: debtorId,
                        PromoCode: promoCode,
                        device_name: process.env.VUE_APP_API_DEVICE,
                        IP: this.ipUser ? this.ipUser : '',
                        OS:navigator.platform
                    })
                    this.loading =  false;
                    this.eventCallbackConfirmSuccessGTM();
                    this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol + '//' + this.host + '/dashboard', 'Dashboard');

                   this.$router.push({ name: 'dashboard' });
                } catch (error) {
                    this.flashMessage.show({
                        timeout:300,
                        status: 'error',
                        position:'top right', 
                        title: 'Erro', 
                        message: 'CPF/CNPJ ou código de acesso incorretos'});
                    this.loading = false;

                    this.eventCallbackConfirmFailureGTM(error);
                    
                    return;
                }
            
        },
        
        voltar() {
            this.eventsendAgainCodeGTM();
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/receber-codigo', 'receber-codigo');
            this.updateCustomData();
            this.ActionSetCurrentForm("form-send-code");
        },

        buildAndSetUserData(doc, id, pageLocation, pageName) {
          const userData = {
                          cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                          id: id, // Substitua pelo ID real
                          page_location: pageLocation,
                          pageName: pageName
                        };
                        localStorage.setItem("userData", JSON.stringify(userData));
        },

        eventChangeAccessCodeInputGTM() {

          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = 'preencher:input:codigo-de-acesso-enviado';
          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log('changeAccessCodeInput SENTCODE: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });
            
        },

        eventsendAgainCodeGTM() {

          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = 'click:link:reenviar-codigo';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log('eventData sendAgainCode: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventConfirmCodeGTM() {

          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = 'click:button:confirmar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log('eventData confirm: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventCallbackConfirmSuccessGTM() {
          const typesent = localStorage.getItem("typesent");
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction =`token:${typesent}:sucesso`;

          const eventData = {
            event: 'token',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'validado',
          };

          console.log("eventData Código SUCESSO: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'token', ...eventData });
        },

        eventCallbackConfirmFailureGTM(error) {
                    const pageName = this.$options.name;
                    const eventCategory = `portal-de-negociacao:porto:${pageName}`;
                    const eventAction = 'confirmar-codigo-de-acesso:alert';
                    const eventLabel = error.data.message;
                    const eventCode = error.status;
                    const eventErrorService = '';
                    const eventAlertService = error.data.message;
                    const eventAlert = 'token';
                    const eventAlertType = 'erro';
                    const eventData = {
                      event: 'alert',
                      ev_category: eventCategory,
                      ev_action: eventAction,
                      ev_label: eventLabel,
                      alert_code: eventCode,
                      error_service: eventErrorService,
                      alert_service_message: eventAlertService,
                      alert_event: eventAlert,
                      alert_type: eventAlertType,
                    };

                    console.log("eventData Envio de Código ERRO: " + JSON.stringify(eventData))

                    window.dataLayer.push({ event: 'alert', ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    }

    },
}
</script>
<style scoped>


</style>