<template>
    <keep-alive>
        <component v-bind:is="currentForm"></component>
    </keep-alive>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormDocNumber from './FormDocNumber';
import FormPromoCode from './FormPromoCode';
import Verificado from './Verificado';
import FormSendCode from './FormSendCode';
import FormPromoCodeSent from './FormPromoCodeSent';
import Index from './Index';
import FakeLoading from './FakeLoading';

export default {
    components: {
        "index": Index,
        "form-doc-number": FormDocNumber,
        "form-promo-code": FormPromoCode,
        "verificado":Verificado,
        "form-send-code":FormSendCode,
        "form-code-sent":FormPromoCodeSent,
        "fake-loading":FakeLoading,
    },

    data: () => ({
        
    }),

    async created() {
        await this.ActionDoRegisterSession();
        // localStorage.clear();
    },

    computed: {
        ...mapState('auth', ['currentForm'])
    },

    methods: {
        ...mapActions('auth', ['ActionDoRegisterSession']),
    },
}
</script>
