<template>
  <button
    type="button"
    class="btn-link position-absolute"
    @click="togglePasswordVisibility"
    :style="{
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      padding: '0',
      zIndex: '2',
    }"
  >
    <i :class="!isPasswordVisible ? 'fa fa-1x fa-eye-slash' : 'fa fa-1x fa-eye'"></i>
  </button>
</template>

<script>
export default {
  props: {
    isPasswordVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.$emit("toggle-visibility");
    },
  },
};
</script>

<style scoped>
/* Estilos específicos para o botão */
</style>
