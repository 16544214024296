<template>
  <div
    id="inside"
    style="background-color: #f1f6ff !important; min-height: 100vh"
  >
    <div
      class="container mb-3"
      v-if="fullName !== null"
      style="margin-top: 60px; max-width: 1140px !important"
    >
      <div class="row">
        <div
          v-bind:class="[
            largura < 500 ? 'col-12 col-lg-12 p-3' : 'col-12 col-lg-12 p-0',
          ]"
        >
          <h5 class="text-body mb-1">Olá, {{ fullName }}</h5>
          <p class="text-info mb-2">
            CPF
            {{
              formatDocument(accessdocFormated).split(".")[0].substring(0, 6) +
              ".XXX" +
              "." +
              "XXX-" +
              formatDocument(accessdocFormated).split("-")[1]
            }}
          </p>
          <p class="text-info mb-3">
            Confira as opções de negociação disponíveis:
          </p>
        </div>
      </div>
    </div>
    <nav
      class="navbar navbar-expand fixed-top"
      style="max-height: 63px !important"
    >
      <div class="container" style="max-width: 1140px !important">
        <a class="navbar-brand text-primary" href="/">
          <img
            @click="clickLogo()"
            class="d-block"
            src="/img/LogoPortoBank1.svg"
            height="23 vh"
          />
        </a>
        <p class="title-space mb-2 mt-2">Negocie Porto Bank</p>
        <ul class="navbar-nav ml-auto text-secondary">
          <a
            class="sidebar-brand d-flex align-items-right justify-content-right"
            href="index.html"
          >
          </a>
          <li class="nav-item dropdown no-arrow">
            <a
              class="sidebar-brand d-flex align-items-right justify-content-right"
              href="index.html"
            >
            </a>
          </li>
          <li
            class="nav-item dropdown no-arrow text-secondary"
            v-if="fullName !== null"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="mr-2 small">{{ fullName.split(" ")[0] }}</span>
              <i class="fa fa-bars fa-fw fa-lg"></i>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <button class="dropdown-item" @click="alterarCadastro()">
                <i class="fa fa-user-o fa-sm fa-fw mr-2"></i> Cadastro
              </button>
              <button class="dropdown-item" @click="alterarSenha()">
                <i class="fa fa-key fa-sm fa-fw mr-2"></i> Alterar código
              </button>
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logout"
              >
                <i class="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container" v-if="loading">
      <ProgressLoading v-bind:loading="loading" :style="fullName === null ? 'margin-top: 50px;' : ''" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";
import ProgressLoading from "./ProgressLoading.vue";
import { formatDocument, ActionGetIPAddress } from "@/utils/helpers";

export default {
  name: "Fake-Loading",
  components: {
    ProgressLoading,
  },
  data() {
    return {
      loading: false,
      fullName: null,
      accessdoc: null,
      promoCode: null,
      accessdocFormated: null,
      userIdAdm: null,
      url: null,
      protocol: null,
      path: null,
      host: null,
      ipUser: null,
    };
  },
  async created() {
    await this.getIPAddressUser();
    this.largura = window.screen.width;
    this.loading = true;
    this.url = window.location.href;
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + "//" + this.host + this.path;
    // eslint-disable-next-line
    // debugger;
    const infoEncrypted = localStorage.getItem("infoEncrypted");
    const encryptedData = localStorage.getItem("encryptedData");

    if (!infoEncrypted && !encryptedData) {
      this.flashMessage.show({
        timeout: 300,
        status: "error",
        position: "top right",
        title: "Erro",
        message: "Não foi possível acessar informações do devedor.",
      });
      return;
    }

    if (infoEncrypted) {
      this.verifyIfIsAccessLinkAdmAsDebtor(infoEncrypted);
    }
    
    if (encryptedData) {
      this.verifyIfIsDirectAccessLink(encryptedData);
    }
  },

  methods: {
    formatDocument,
    ...mapActions("auth", ["ActionGetLinkAccessAsDebtor"]),
    ...mapActions("auth", [
      "ActionSetCurrentForm",
      "ActionGetContactsByDocNumber",
      "ActionDoLogin",
      "ActionGetLinkDirectAccess",
    ]),

    buildAndSetUserData(doc, id, pageLocation) {
      const userData = {
        cpfCnpj: doc,
        id: id,
        page_location: pageLocation,
      };
      console.log(
        "buildAndSetUserData FAKELOADING: " + JSON.stringify(userData)
      );
      localStorage.setItem("userData", JSON.stringify(userData));
    },

    async verifyIfIsAccessLinkAdmAsDebtor(infoEncrypted) {
      this.loading = true;

      var result = await this.ActionGetLinkAccessAsDebtor({
        infoEncrypted: infoEncrypted,
      });

      if (!result[0].Message) {
        try {
          var data = result[0];
          this.accessdoc = data.DocNumber;
          this.promoCode = data.PromoCode;
          this.fullName = data.FullName;
          this.accessdocFormated = this.formatarCnpjCpf(this.accessdoc);
          const userAdm = {
            UserID: data.UserID,
            UserFullName: data.UserFullName,
          };

          localStorage.setItem("userAdm", JSON.stringify(userAdm));

          if (this.accessdoc && this.promoCode) {
            this.login(
              this.accessdoc,
              this.promoCode,
              userAdm.UserID,
              userAdm.UserFullName
            );
          }

        } catch (error) {
          console.log("error: " + JSON.stringify(error));
          this.loading = false;
        }
      } else {
        this.flashMessage.show({
          timeout: 300,
          status: "error",
          position: "top right",
          title: "Erro",
          message: result[0].Message,
        });
        this.loading = false;
        this.ActionSetCurrentForm("form-doc-number");
      }
    },

    async verifyIfIsDirectAccessLink(encryptedData) {
      this.loading = true;

      try {
        const response = await this.ActionGetLinkDirectAccess({
          encryptedData: encryptedData,
          IP: this.ipUser ? this.ipUser : "",
          DeviceName: process.env.VUE_APP_API_DEVICE,
          OS: navigator.platform,
        });

        if (response.success) {
          const accessdoc = response.docNumber;
          const debtorId = response.debtorId;
  
          this.buildAndSetUserData(
            accessdoc,
            debtorId,
            this.protocol + "//" + this.host + "/dashboard"
          );
  
          this.$router.push({ name: "dashboard" });
  
          localStorage.setItem(
            "authUser",
            JSON.stringify(this.$store.state.auth.user)
          );
  
          window.location.reload();
  
          this.loading = false;
        } else {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            title: "Erro",
            message: response.message,
          });
          this.ActionSetCurrentForm("form-doc-number");
        }
      } catch (error) {
        console.log("error: ", error);
        this.loading = false;
      }
    },
    async login(accessdoc, promocode, userId, userFullName) {
      this.loading = true;

      let response = await this.ActionGetContactsByDocNumber({
        DocNumber: accessdoc,
        OS: navigator.platform,
        UserID: userId,
        UserFullName: userFullName,
        IP: this.ipUser ? this.ipUser : "",
      });

      if (!response.data.code || response.data.code !== 200) {
        let url = "https://portoseguro.com.br/negocie";
        this.flashMessage.show({
          time: 0,
          status: "error",
          position: "top right",
          title: "Erro",
          message:
            "Ops... tivemos um problema. Ligue para: SP: 4004-3600 Demais regiões: 0800 727 7477.",
          html: `<div class="m-2"><p>Tivemos um problema. Encontre outros canais de negociação em:  <a href="${url}" target="_blank">${url}</a></p></div>`,
        });

        this.loading = false;
        return;
      }

      if (response.data.results.SourceInfo == "EXTERNO") {
        this.ActionSetContacts({
          DocNumber: response.data.results.DocNumber,
          Emails: response.data.results.Emails,
          PhoneNumbers: response.data.results.MobileNumbers,
          ListEmailsWithMask: response.data.results.ListEmailsWithMask,
          ListPhonesWithMask: response.data.results.ListPhonesWithMask,
          SourceInfo: "API_EXTERNA",
        });
        this.contacts = response.data.results;
        this.loading = false;
      } else {
        let debtorId = response.data.results.DebtorID;
        let promoCode = promocode;

        try {
          await this.ActionDoLogin({
            DebtorID: debtorId,
            PromoCode: promoCode,
            device_name: process.env.VUE_APP_API_DEVICE,
            IP: this.ipUser ? this.ipUser : "",
            OS: navigator.platform,
            UserID: userId,
            UserFullName: userFullName,
          });
          this.loading = false;

          this.buildAndSetUserData(
            accessdoc,
            debtorId,
            this.protocol + "//" + this.host + "/dashboard"
          );

          this.$router.push({ name: "dashboard" });

          localStorage.setItem(
            "authUser",
            JSON.stringify(this.$store.state.auth.user)
          );

          window.location.reload();
        } catch (error) {
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = "login:alert";
          const eventLabel = error.data.message;
          const eventCode = error.data.status;
          const eventErrorService = "";
          const eventAlertService = error.data.message;
          const eventAlert = "login";
          const eventAlertType = "bloqueio";
          const eventData = {
            event: "alert",
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: eventLabel,
            alert_code: eventCode,
            error_service: eventErrorService,
            alert_service_message: eventAlertService,
            alert_event: eventAlert,
            alert_type: eventAlertType,
          };

          window.dataLayer.push({ event: "alert", ...eventData });
          console.log("ERROOOORRR: " + JSON.stringify(error));

          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            title: "Erro",
            message:
              "CPF/CNPJ ou código de acesso incorretos",
          });
          this.loading = false;
          return;
        }
      }
    },

    formatarCnpjCpf(cnpjCpf) {
      const numeros = cnpjCpf.replace(/\D/g, "");
      if (numeros.length === 11) {
        // CPF
        return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else if (numeros.length === 14) {
        return numeros.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      } else {
        return cnpjCpf;
      }
    },

    async getIPAddressUser() {
      let maxAttempts = 10;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          this.ipUser = await ActionGetIPAddress();
          break;
        } catch (error) {
          console.log("Erro ao obter o endereço IP. Tentando novamente...");
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      if (attempts === maxAttempts) {
        console.log(
          "Número máximo de tentativas atingido. Não foi possível obter o endereço IP."
        );
      }
    },
  },

  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>
<style></style>
