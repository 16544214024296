<template>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" href="img/favicon.ico">
  <!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" type="text/css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/font-awesome.min.css" type="text/css">
  <link rel="stylesheet" href="theme.css" type="text/css"> -->
  <title>Porto - Portal de Autonegociação</title>
</head>

<body>
  <div class="capa1" style="">
    <div class="container">
      <div class="row mt-5">
        <div class="mx-auto p-4 col-lg-6 col-md-6 col-1" style=""></div>
        <div class="col-md-4 col-lg-4 col-9" style="">
          <h6 class="text-white">Negociação de Dívidas</h6>
          <h1 class="col-11 pl-0 text-white">Condições especiais para você ficar no azul</h1>
          <h6 class="mb-5 text-white">As melhores condições para você quitar as dívidas do seu cartão e dos demais produtos Porto.</h6><a class="btn mx-auto btn-lg btn-secondary" @click="login" href="#">Negociar cartão</a>
        </div>
        <div class="col-md-1 col-lg-1 col-1" style="">
        </div>
      </div>
    </div>
  </div>
  <div class="sec10 bg-light">
    <div class="container">
      <div class="row justify-content-center text-center mb-5">
        <div class="col-md-12 text-dark">
          <h2 class="py-4">Como fazer a negociação online do seu Cartão de Crédito Porto Bank </h2>
        </div>
      </div>
      <div class="row d-flex justify-content-center text-dark">
        <div class="col-6 col-lg-3 p-2 pl-3 pr-4" style="">
          <img class="img-fluid d-block mb-3" src="img/ic01.jpg">
          <h5>Identifique-se fazendo o login.</h5>
        </div>
        <div class="col-6 col-lg-3 p-2 pr-4 pl-3" style="">
          <img class="img-fluid d-block mb-3" src="img/ic02.jpg">
          <h5>Selecione a melhor opção para você.</h5>
        </div>
        <div class="col-6 col-lg-3 p-2 pr-4 pl-3" style="">
          <img class="img-fluid d-block mb-3" src="img/ic03.jpg">
          <h5 class="" style="">Gere o boleto e faça o pagamento.</h5>
        </div>
        <div class="col-6 col-lg-3 p-2 pr-4 pl-3" style="">
          <img class="img-fluid d-block mb-3" src="img/ic04.jpg">
          <h5 class="">Pronto! Tudo certo com a sua negociação.</h5>
        </div>
      </div>
    </div>
  </div>
</body>

</html>
</template>

<script>
// import { mask } from 'vue-the-mask';
import { mapActions, mapState } from 'vuex';
// import { validationMixin } from 'vuelidate';
// import { required, minLength } from 'vuelidate/lib/validators';
// import ProgressLoading from './ProgressLoading.vue';

export default {
    // components: {
    //     ProgressLoading
    // },
    // mixins: [validationMixin],
    // directives: {mask},
    // validations: {
    //     form: {
    //         promoCode: {required, minLength: minLength(5)},
    //     }
    // },
    data: () => ({
        // form: {
        //     promoCode: null
        // },
        // error: {
        //     alert: false,
        //     status: null,
        //     message: null
        // },
        // loading: false,
        // dialog: false,
        largura: null
    }),
    async created() {
        this.largura = window.screen.width;
    },
    computed: {
        ...mapState('auth', ['contacts']),

        // classMessage: function () {
        //     return {
        //         'text-danger': this.error.status == "ERROR",  
        //         'text-muted': !this.error.status == "ERROR"
        //     }
        // },

        // promoCodeErrors () {
        //     const errors = [];

        //     if(!this.$v.form.promoCode.$dirty) return errors;
        //     !this.$v.form.promoCode.required && errors.push('Código é obrigatório!');
        //     !this.$v.form.promoCode.minLength && errors.push('O Código não está completo!');

        //     return errors;
        // },
    },
    methods: {
        ...mapActions('auth', ['ActionDoLogin', 'ActionSetCurrentForm']),

        // async login() {
        //     this.promoCodeErrors.pop();
        //     this.$v.$touch();

        //     if (this.$v.$invalid) {
        //         // formulário inválido
        //     } else {
        //         this.loading = true;
        //         let debtorId = this.contacts.DebtorID;
        //         let promoCode = this.form.promoCode;
        //         var ipmachine = await this.ActionGetIPAddress();

        //         try {
        //             await this.ActionDoLogin({
        //                 DebtorID: debtorId,
        //                 PromoCode: promoCode,
        //                 device_name: process.env.VUE_APP_API_DEVICE,
        //                 IP:ipmachine,
        //                 OS:navigator.platform
        //             })
        //             this.loading =  false;
        //             this.ActionSetCurrentForm('verificado');
        //             //this.$router.push({ path: 'dashboard',foce:true });
                    
        //            // this.$router.push({ name: 'dashboard' });
        //         } catch (error) {
        //             this.flashMessage.show({
        //                 timeout:300,
        //                 status: 'error',
        //                 position:'top right', 
        //                 title: 'Erro', 
        //                 message: 'ocorreu um erro ao autenticar, promocode incorreto ou inválido'});
        //             this.loading = false;
        //             return;
        //         }
        //     }
        // },
        sendCodeForm() {
            this.ActionSetCurrentForm('form-send-code');
        },
        login() {
            this.ActionSetCurrentForm("form-doc-number");
        }
    },
}
</script>
<style scoped>


</style>