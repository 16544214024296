<template>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" href="img/favicon.ico">
  <!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" type="text/css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/font-awesome.min.css" type="text/css">-->
  <link rel="stylesheet" href="theme.css" type="text/css">
  <title>Porto - Portal de Autonegociação</title>
</head>

<body>
  <div class="capa2">
    <div class="container">
      <div class="row">
        <div class="mx-auto p-4 col-10 col-lg-4"></div>
        <div class="col-md-4">
          <div class="mx-auto bg-light p-3 lpp4">
            <div class="border-top-0 px-3 pt-5">
              <h6 class="mb-4 text-center">Selecione como deseja receber seu código:</h6>
              <form v-on:submit="sendNewContact" v-on:submit.prevent id="form">
                <div class="col-lg-12 small col-12">
                  <div class="custom-control custom-radio custom-control-inline mx-3">
                    <input
                      type="radio"
                      id="customRadioInline1"
                      value="sms"
                      name="EmailOrPhone"
                      class="custom-control-input"
                      v-model="selectedOption"
                      @input="selectRadio(2)"
                    />
                    <label class="custom-control-label pt-1" for="customRadioInline1">SMS</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline mx-3">
                    <input
                        type="radio"
                        value="email"
                        class="custom-control-input"
                        id="customRadioInline2"
                        name="EmailOrPhone"
                        v-model="selectedOption"
                        @input="selectRadio(1)"
                      />
                    <label class="custom-control-label pt-1" for="customRadioInline2">Email</label>
                  </div>
                </div>
                 <div class="form-group mb-3 " v-if="selectedOption === 'sms' && (form.phoneList.length <= 0) && (form.emailList.length <= 0)">
                  <!-- <div class="form-group mb-3 " v-if="selectedOption === 'sms'"> -->

                  <the-mask
                  v-if="selectedOption === 'sms'"
                  type="tel"
                  class="
                    form-control my-4 form-control-sm
                  "
                  placeholder="Digite seu número de celular"
                  id="formSms"
                  v-model="form.newPhone"
                  @input="typeNewPhone"
                  mask="(##) # ####-####"
                ></the-mask>
                </div>
                <div class="input-group mb-3" v-if="selectedOption === 'sms'">
                  <multiselect
                    v-if="selectedOption === 'sms' && (form.phoneList.length > 0)"
                    v-model="form.phoneSelected"
                    :multiple="false"
                    :select-label="'selecionar'"
                    deselect-label="remover"
                    track-by="PhoneNumber"
                    label="PhoneNumber"
                    placeholder="Selecione o telefone"
                    :options="form.phoneList"
                    :searchable="false"
                    :allow-empty="true"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.PhoneNumber
                    }}</template>
                    <template v-slot:noOptions
                      >Não foi encontrado nenhum telefone</template
                    >
                  </multiselect>
                </div>
                <div class="form-group mb-3 " v-if="selectedOption === 'email' && (form.emailList.length <= 0) && (form.phoneList.length <= 0)">
                <!-- <div class="form-group mb-3 " v-if="selectedOption === 'email'"> -->
                  <input type="text" class="form-control my-4 form-control-sm" placeholder="Digite seu e-mail" id="form" label="Digite"
                  v-model="form.newEmail"
                  @input="typeNewEmail">
                </div>
                <div class="input-group mb-3" v-if="selectedOption === 'email'">
                  <div class="input-group mb-3" v-if="selectedOption === 'email'">
                  <multiselect
                    v-model="form.emailSelected"
                    v-if="selectedOption === 'email' && form.emailList.length > 0"
                    :multiple="false"
                    :select-label="'selecionar'"
                    deselect-label="remover"
                    track-by="Email"
                    label="Email"
                    placeholder="Selecione um e-mail"
                    :options="form.emailList"
                    :searchable="false"
                    :allow-empty="true"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.Email
                    }}</template>
                    <template v-slot:noOptions
                      >Não foi encontrado nenhum email cadastrado</template
                    >
                  </multiselect>
                </div>
                </div>
                <button v-if="selectedOption === 'sms'" :disabled="loading || (!form.phoneSelected && !form.newPhone)" type="submit" class="btn btn-primary btn-lg d-flex my-4 mx-auto">Enviar código</button>
                <button v-if="selectedOption === 'email'" :disabled="loading || (!form.emailSelected && !form.newEmail)" type="submit" class="btn btn-primary btn-lg d-flex my-4 mx-auto">Enviar código</button>
              </form>
              <ProgressLoading v-bind:loading="loading" />

              <p class="small text-center mt-4">
                <a @click="voltar" class="text-primary" href="#" style="text-decoration: none;">Já tenho o código</a>
              </p>
            </div>
          </div>
        </div>
        <div class="mx-auto p-4 col-10 col-lg-4"></div>
      </div>
    </div>
  </div>
</body>

</html>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { TheMask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ProgressLoading from "./ProgressLoading.vue";
import Multiselect from "vue-multiselect";
import { EventBus } from "@/utils/EventBus";
import { initializeGlobals } from '@/global';


export default {
  name: 'receber-codigo',
  components: {
    ProgressLoading,
    Multiselect,
    TheMask,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      telefone: { required, minLength: minLength(10) },
    },
  },
  data: () => ({
    form: {
      emailSelected: null,
      phoneSelected: null,
      emailList: [],
      phoneList: [],
      newEmail: '',
      newPhone: '',
      userDataStorage: {},
      pageLocation: null,
      url: null,
      protocol: null,
      path: null,
      host: null,
    },
    error: {
      status: null,
    },
    loading: false,
    phoneNumberFinal: null,
    emailFinal: null,
    selectedOption: 'sms',
    width: null,
    multiselectMessages: {
      search: 'Buscar', // Mensagem para o campo de busca
      noResult: 'Sem resultados encontrados', // Mensagem quando não há resultados
      tagPlaceholder: 'Selecionar', // Mensagem padrão do campo de seleção
    },
    ipUser: null
  }),
  mounted() {
    this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.pageLocation, 'receber-codigo');
    localStorage.setItem("friendlyPageName", 'Receber Código');
    this.$emit('updatePageView', this.pageLocation);
  },
  created() {
    this.updateCustomData();
    this.ipUser = localStorage.getItem('ipUser');
    localStorage.setItem("currentPageName", this.$options.name);
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + '//' + this.host + this.path;
    this.width = window.screen.width;
    if(typeof this.contacts.ListEmailsWithMask.Email !== "undefined") {
      this.contacts.ListEmailsWithMask.Email.map((email) => {
        if (!this.form.emailList.find((item) => item.Email === email)) {
          this.form.emailList.push({
            Email: email
          });
        }
      });

      this.form.emailSelected = this.form.emailList.length > 0 ? this.form.emailList[0] : null;
    } else {
      this.form.emailSelected = null;
    }

    if(typeof this.contacts.ListPhonesWithMask.PhoneNumber !== "undefined") {
      this.contacts.ListPhonesWithMask.PhoneNumber.map((phone) => {
        if (!this.form.phoneList.find((item) => item.PhoneNumber === phone)) {
          this.form.phoneList.push({
            PhoneNumber: phone
          });
        }
      });

      this.form.phoneSelected = this.form.phoneList.length > 0 ? this.form.phoneList[0] : null;
    } else {
      this.form.phoneSelected = null;
    }
  },
  computed: {
    ...mapState("auth", ["contacts"]),
  },
  methods: {
    ...mapActions("auth", [
      "ActionSendPromoCode",
      "ActionSetCurrentForm",
      "ActionCreateDebtor",
      "ActionSetContacts",
    ]),

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      const userData = {
                      cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                      id: id, // Substitua pelo ID real
                      page_location: pageLocation,
                      pageName: pageName
                    };
                    localStorage.setItem("userData", JSON.stringify(userData));
    },

    selectRadio(value) {
      if (value === 1) {
        this.form.newPhone = '';
        this.form.phoneSelected = null;
      } else {
        this.form.newEmail = '';
        this.form.emailSelected = null;
      }
    },

    typeNewEmail() {
      this.form.emailSelected = null;
    },

    typeNewPhone() {
      this.form.phoneSelected = null;
    },

    eventClickSendCodeGTM() {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction ='click:button:enviar-o-codigo';

      const eventData = {
        event: 'select_content',
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: '',
      };

      window.dataLayer.push({ event: 'select_content', ...eventData });
    },

    eventSentCodeSuccessGTM(typeSent) {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction =`token:${typeSent}:sucesso`;

      const eventData = {
        event: 'token',
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: 'enviado',
      };

      window.dataLayer.push({ event: 'token', ...eventData });
    },


    eventSentCodeErrorGTM(error) {
      const pageName = this.$options.name;
                    const eventCategory = `portal-de-negociacao:porto:${pageName}`;
                    const eventAction = 'enviar-codigo:alert';
                    const eventLabel = error.message;
                    const eventCode = 401;
                    const eventErrorService = '';
                    const eventAlertService = error.message;
                    const eventAlert = 'token';
                    const eventAlertType = 'erro';
                    const eventData = {
                      event: 'alert',
                      ev_category: eventCategory,
                      ev_action: eventAction,
                      ev_label: eventLabel,
                      alert_code: eventCode,
                      error_service: eventErrorService,
                      alert_service_message: eventAlertService,
                      alert_event: eventAlert,
                      alert_type: eventAlertType,
                    };

                    window.dataLayer.push({ event: 'alert', ...eventData });
    },


    async sendNewContact(e) {
      
      this.eventClickSendCodeGTM();
      this.emailFinal = null;
      this.phoneNumberFinal = null;
      var typeSent = null;

      e.preventDefault();
      this.$v.$touch();

      if (this.selectedOption === 'email') {
        if (!this.form.newEmail && !this.form.emailSelected.Email) {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            blockClass: "custom-block-class",
            title: "Erro",
            message: "informe ao menos um meio de contato",
          });
          return;
        }
        if (this.form.emailSelected) {
          this.emailFinal = this.form.emailSelected.Email
        } else {
          this.emailFinal = this.form.newEmail;
        }

        typeSent = 'email';
      }

      if (this.selectedOption === 'sms') {
        if (
          !this.form.newPhone &&
          !this.form.phoneSelected.PhoneNumber
        ) {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            blockClass: "custom-block-class",
            title: "Erro",
            message: "informe ao menos um meio de contato",
          });
          return;
        }

        if (this.form.phoneSelected != null && this.form.phoneSelected != "") {
          this.phoneNumberFinal = this.form.phoneSelected.PhoneNumber;
        } else {
          this.phoneNumberFinal = this.form.newPhone;
        }

        typeSent = 'sms';
      }

      localStorage.setItem("typesent", typeSent);       

      this.loading = true;
      // this.telefoneErrors.pop();
      if (this.contacts.SourceInfo === "API_EXTERNA") {
        try {
          var response = await this.ActionCreateDebtor({
            FullName: this.contacts.FullName ? this.contacts.FullName : null,
            DocNumber: this.contacts.DocNumber,
            Email: this.emailFinal,
            PhoneNumber:
              this.phoneNumberFinal != null
                ? this.phoneNumberFinal : null,
            OS: navigator.platform,
            IP: this.ipUser ? this.ipUser : '',
          });


          if (response.status === 200) {
            this.ActionSetContacts({
              DebtorID: response.data.results.DebtorID,
            });
            this.flashMessage.show({
              timeout: 300,
              status: "success",
              position: "top right",
              blockClass: "custom-block-class",
              title: "Sucesso",
              message: "código enviado com sucesso",
            });

            this.eventSentCodeSuccessGTM(typeSent);

            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/codigo-enviado', 'codigo-enviado');
            this.emitUpdatePageViewEvent('/dashboard/codigo-enviado', 'Código Enviado');
            this.updateCustomData();
            this.ActionSetCurrentForm("form-code-sent");
            this.loading = false;
          } else {
            this.loading = false;
            this.error.status = "ERROR";

            this.flashMessage.show({
              timeout: 300,
              status: "error",
              position: "top right",
              blockClass: "custom-block-class",
              title: "Erro",
              message:
                "ocorreu um erro ao tentar enviar o código de acesso, tente novamente mais tarde",
            });

            const error = {
              message: "ocorreu um erro ao tentar enviar o código de acesso, tente novamente mais tarde",
              status: ''
            };

            this.eventSentCodeErrorGTM(error);

          }
        } catch (error) {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            blockClass: "custom-block-class",
            title: "Erro",
            message: error.data.message,
          });

            this.eventSentCodeErrorGTM(error);
          this.error.status = "ERROR";
        }
      } else {
        console.log('this.phoneNumberFinal: ' + JSON.stringify(this.phoneNumberFinal))
        var result = await this.ActionSendPromoCode({
          DocNumber: this.contacts.DocNumber,
          DebtorId: this.contacts.DebtorID,
          Email: this.emailFinal,
          PhoneNumber:
            this.phoneNumberFinal != null
              ? this.phoneNumberFinal
              : null,
              OS: navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
        });

        if (result.status === 500 || result.status !== 200) {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            blockClass: "custom-block-class",
            title: "Erro",
            message: result.data?.message ?? "Erro na solicitação",
          });

          this.eventSentCodeErrorGTM(result.data);
        } else {
          this.flashMessage.show({
            timeout: 300,
            status: "success",
            position: "top right",
            blockClass: "custom-block-class",
            title: "Sucesso",
            message: "código enviado com sucesso",
          });

          this.eventSentCodeSuccessGTM(typeSent);

          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/codigo-enviado', 'codigo-enviado');
          this.emitUpdatePageViewEvent('/dashboard/codigo-enviado', 'Código Enviado');
          this.updateCustomData();
          this.ActionSetCurrentForm("form-code-sent");
        }
      }
      this.loading = false;
      // }
    },

    eventClickAlreadyCodeGTM() {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction ='click:link:ja-tenho-codigo';

      const eventData = {
        event: 'select_content',
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: '',
      };

      window.dataLayer.push({ event: 'select_content', ...eventData });
    },

    voltar() {
      localStorage.setItem("currentPageName", 'possuo-o-codigo');
      localStorage.setItem("typesent", this.selectedOption);

      this.eventClickAlreadyCodeGTM();
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/possuo-o-codigo', 'possuo-o-codigo');
      this.emitUpdatePageViewEvent('/dashboard/possuo-o-codigo', 'Possuo o Código');
      this.updateCustomData();
      this.$destroy();
      this.ActionSetCurrentForm("form-promo-code");
    },

    emitUpdatePageViewEvent(pageName, friendlyPageName) {
        EventBus.$emit('updatePageView', pageName, friendlyPageName);
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    }

  },
};
</script>
<style>

/* body {
  background: #000 !important;
} */


.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:before,
.multiselect__spinner:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 14px;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 1px 0 0 5px;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 6px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "×";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: white;
}
.multiselect__current {
  line-height: 16px;
  min-height: 40px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}
.multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #999999 transparent transparent transparent;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  /* padding: 12px; */
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
}
.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: white;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: white;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: bold;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed;
  color: #a6a6a6;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede !important;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 10px;
  display: inline-block;
}
*[dir="rtl"] .multiselect {
  text-align: right;
}
*[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
*[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0px 40px;
}
*[dir="rtl"] .multiselect__content {
  text-align: right;
}
*[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
*[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
*[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
</style>
